<template>
  <div class="container">
    <header-back name='个人中心'></header-back>
    <section class="top44">
      <div class="user-cen">
        <van-cell
          title="修改密码"
          is-link
          to='/changPwd'
        />
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";

export default {
  components: { HeaderBack },
  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 16px 15px;
  box-sizing: border-box;
}
.user-cen {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}
</style>
